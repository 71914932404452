/* eslint-disable react/no-array-index-key */
import React from 'react'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

const BackgroundWrapper = styled.div`
  max-width: 1680px;
  margin: 0 auto;
  position: relative;

  @media screen and (max-width: 1200px) {
    height: 500px;
  }

  @media screen and (max-width: 991px) {
    height: 400px;
  }

  @media screen and (max-width: 768px) {
    height: 300px;
  }

  @media screen and (max-width: 576px) {
    height: 200px;
  }
`

const AboutWrapper = styled.div`
  background-color: ${(props) => props.theme.color.face.secondary};
`

const UspWrapper = styled.div`
  @media (min-width: 999px) {
    max-height: fit-content;
    margin-bottom: -200px;
  }
`

const UspContainer = styled.div`
  position: relative;

  @media (min-width: 999px) {
    bottom: 170px;
  }
`

const Usp = styled.div`
  background: ${(props) => props.theme.color.face.secondary};
  border-radius: 12px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: ${(props) => props.theme.font.size[32]};

    & ::before {
      content: '';
      display: inline-block;
      margin-right: 5px;
      width: 22px;
      height: 22px;
      border-radius: 50px;
      border: 4px solid ${(props) => props.theme.color.face.contrast};
    }

    @media (max-width: 1200px) {
      font-size: ${(props) => props.theme.font.size[24]};
    }
  }

  @media (min-width: 999px) {
    min-height: fit-content;
  }
  @media (min-width: 400px) {
    min-width: 100%;
  }
`

const StyledBackgroundImage = styled(Plaatjie)`
  width: 100%;
  max-height: 650px;
  min-height: 150px;
  background-position: top center;
  background-size: cover;
  padding-top: 50%;

  @media screen and (max-width: 1200px) {
    padding-top: unset;
    height: 500px;
  }

  @media screen and (max-width: 991px) {
    height: 400px;
  }

  @media screen and (max-width: 768px) {
    height: 300px;
  }

  @media screen and (max-width: 576px) {
    height: 200px;
  }
`

interface UspWithBackgroundImageProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_UspWithBackgroundImage
}

const UspWithBackgroundImage: React.FC<UspWithBackgroundImageProps> = ({
  fields,
}) => (
  <>
    <BackgroundWrapper>
      <StyledBackgroundImage image={fields?.backgroundImage} alt="" />
    </BackgroundWrapper>
    <AboutWrapper className="pt-5 pt-lg-0">
      <UspWrapper className="container py-lg-3 pt-lg-5">
        <UspContainer className="row justify-content-between pb-5">
          {fields?.usp?.map((edge, index: number) => (
            <div key={index} className="col-lg-4 col-md-12">
              <Usp className="p-lg-4 py-4">
                <ParseContent className="p-2" content={edge?.description} />
              </Usp>
            </div>
          ))}
        </UspContainer>
      </UspWrapper>
    </AboutWrapper>
  </>
)

export default UspWithBackgroundImage
